import { addMetaTagsManagerConnection } from '@owc/connection-aem-meta-tags';
import { addVuePlugins, addVueRootLevelElement } from './vue-helpers';
import { addStoreConnections, initializePackages, setupEnvironment } from './helpers';
import { setRouterMainNamespace } from './helpers/global-packages';

declare const VERSION: string;
declare const ENV: string;
const logprefix = `GLOBALS ${VERSION}`;
const prefix = (suffix: string): string => `${logprefix} ${suffix}`;
// eslint-disable-next-line no-undef
export const version = VERSION;
const { logger } = setupEnvironment({ namespace: 'OWC FE LIBS GLOBALS' });

// adding Vue Root Level Element to create the Vue instance and the AEM Listener for Author Pages
addVueRootLevelElement();

// setting the main namespace for the router
setRouterMainNamespace(logger).then(() => {
  // TODO: remove as this should be done via aem-meta-tags-util
  // adding this logic here to be easy to track the removal
  addMetaTagsManagerConnection().then(() => logger.log(prefix('added ConnectionMetaTagsManager')));
});

addStoreConnections({ loggerInstance: logger })
  .then(() => addVuePlugins().catch((e) => Promise.resolve(e)))
  .then((e) => {
    if (e) {
      logger.error(e);
    }
    initializePackages({ loggerInstance: logger });
  })
  .catch((e) => logger.error(e));

// exporting the types from the manifest interface so they can be used outside the globals scope
export * from './manifest-interface';
// exporting the helper methods so they can be used outside the globals scope
export * from './helpers';
