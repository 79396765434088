import { globalStoreInitializer, globalLoggerInitializer } from './global-packages';
import { addConnectionAemEnvironmentConnection } from '@owc/connection-aem-environment';
import { addTranslationManagerConnection } from '@owc/connection-aem-translations';
import { addTrackingReducerConnection } from '@owc/connection-aem-tracking';
import { initializeComponentRegistry } from '@owc/component-registry-connection';
import { defaultConnections, defaultPackages, defaultStarters } from './config';
import { ConnectionsConfig, InitializerConfig, SetupConfig, Starters } from './types';

/**
 * adds commonly used connections. By default if no config is provided it
 * adds a set of default connections. If you provide a logger instance it
 * will log after each initialization.
 * By default it adds a set number of connections that you can consult
 * in the AvailableConnections type
 *
 * @ignore
 * @param {config} ConnectionsConfig config where you provide the desired connections and
 * logger instance
 * @return {*} {void}
 */
export const addStoreConnections = async (config?: ConnectionsConfig): Promise<void> => {
  const logger = config?.loggerInstance;
  const connectionsToBeAdded = config?.connections ? config.connections : defaultConnections;
  for await (const connection of connectionsToBeAdded) {
    switch (connection) {
      case 'AemEnvironment':
        await addConnectionAemEnvironmentConnection();
        logger?.log('added AemEnvironmentConnection');
        break;
      case 'TranslationManager':
        addTranslationManagerConnection().then(() => {
          logger?.log('added TranslationConnection');
        });
        break;
      case 'Tracking':
        addTrackingReducerConnection().then(() => {
          logger?.log('added TrackingReducerConnection');
        });
        break;
    }
  }
  return;
};

/**
 * Initializes commonly used packages. By default if no config is provided it
 * initializes a set of default packages. If you provide a logger instance it
 * will log after each initialization.
 * By default it initializes a set number of packages that you can consult
 * in the AvailablePackages type
 *
 * @ignore
 * @param {config} InitializerConfig config where you provide the desired packages and
 * logger instace
 * @return {*} {void}
 */
export const initializePackages = (config?: InitializerConfig): void => {
  const logger = config?.loggerInstance;
  const packagesToBeAdded = config?.packages ? config.packages : defaultPackages;
  packagesToBeAdded.forEach(async (packageToAdd: any) => {
    switch (packageToAdd) {
      case 'ComponentRegistry':
        initializeComponentRegistry();
        logger?.log('initialized component registry');
        break;
    }
  });
  return;
};

/**
 * Setups globals environment. By default if no config is provided it
 * initializes the store. If you provide a string for the logger it also
 * initializes it with the correct identifier.
 * You can also specify if you don't want to initialize one of them in the
 * config.
 *
 * @ignore
 * @param {config} SetupConfig config where you provide the desired starters and
 * namespace (string that identifies the logs)
 * @return {*} {addedStarters} The starters that were initializes
 */
export const setupEnvironment = (config?: SetupConfig) => {
  const addedStarters: Starters = {};
  let startersToBeAdded: string[] = ['SeamlessStore'];
  if (!config?.namespace && config?.starters) {
    startersToBeAdded = config?.starters.filter((starter) => {
      return starter !== 'SeamlessLogger';
    });
  } else if (config?.namespace) {
    startersToBeAdded = config?.starters ? config?.starters : defaultStarters;
  }

  startersToBeAdded.forEach((starter) => {
    switch (starter) {
      case 'SeamlessStore':
        addedStarters.store = globalStoreInitializer('prod');
        break;
      case 'SeamlessLogger':
        addedStarters.logger = config ? globalLoggerInitializer(config.namespace) : undefined;
        break;
    }
  });
  return addedStarters;
};

export * from './types';
