import VueSeamlessStore from '@seamless/vue-store-plugin';

declare global {
  interface Window {
    Vue: any;
    vueInstance: any;
  }
}

/**
 *
 * Ensures that Vue as a root element set in order to give OneWeb pages the ability to
 * run out of the box vue components.
 *
 * Furthermore it ensures that, whenever, in author mode a listener is registered to
 * correctly update the vue components when they are authored via AEM.
 *
 * There is a custom event that is being created int the Seamless Integrator side that
 * triggers when a component has been authored. The event is named "component-updated"
 * and can be found here -> https://jira.mercedes-benz.io/browse/OWCS-996
 *
 * @returns void
 */
export const addVueRootLevelElement = (): void => {
  if (window.Vue) {
    window.addEventListener('DOMContentLoaded', function () {
      const vueInstance = new window.Vue();
      const vueComponentElements = document.querySelectorAll(
        '[data-integration-type="vuecomponent"]:not([data-vue-version^="3"])',
      );
      vueComponentElements.forEach((vueComponentElement) => {
        new window.Vue({
          el: vueComponentElement,
        });
      });
      window.vueInstance = vueInstance;
    });
  }

  // running only on AUTHOR
  if (window.top?.aemNamespace.pageEnvironmentVariables.runMode === 'AUTHOR') {
    const iframe = (window as any).top.document.querySelector('iframe');
    if (iframe) {
      iframe.contentWindow.document.addEventListener('component-updated', updateVueComponents);
    }
  }
};

/**
 *
 * Checks if the updated component was already registered in the windows' vue instance
 * and if so instantiates it and attaches it to the correct element on the DOM tree.
 * @returns void
 */
const updateVueComponents = (event: any): void => {
  const iframe = (window as any).top.document.querySelector('iframe');
  if (iframe) {
    const vueInstance = iframe.contentWindow.vueInstance;

    const target: HTMLElement = event.target;

    const componentName = target.tagName.toLowerCase();

    const componentInstance = vueInstance.$options.components[componentName];

    if (componentInstance) {
      const component = new componentInstance({
        propsData: {
          componentId: target.getAttribute('component-id'),
          dataEditMode: target.getAttribute('data-edit-mode'),
        },
      });

      component.$mount();

      const cqEditableDomContainers = target.querySelectorAll('.cq-Editable-dom--container');

      cqEditableDomContainers.forEach((div) => {
        component.$el.prepend(div);
      });

      target.prepend(component.$el);
    }
  }
};

export const addVuePlugins = async (): Promise<boolean | Error> => {
  if (window.Vue) {
    window.Vue.use(VueSeamlessStore);
    return false;
  }
  return new Error('Vue seems not defined on window');
};
