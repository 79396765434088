import {
  Environment,
  SeamlessConnection,
  PersistentConnection,
  ConnectionExistsError,
  InvalidConnectionNameError,
  initializeStore,
} from '@seamless/store';
import { SeamlessLogger, initializeLogger } from '@seamless/logger';
import { setMainNamespace } from '@seamless/router';

const topMostWindow = (window.top as any) || window;

/**
 * Sets the main router namespace for the components that have routing enabled
 * Turning this async only to cope with the current meta tags logic
 * @returns
 */
export const setRouterMainNamespace = async (logger: ReturnType<typeof initializeLogger>) => {
  const componentIds = Object.keys(window.aemNamespace.componentData);

  for (const componentId of componentIds) {
    const componentData = window.aemNamespace.componentData[componentId];
    if (componentData.config?.routingEnabled) {
      logger.log(`Setting main namespace for router: ${componentData.config.routerNamespace}`);
      setMainNamespace(componentData.config.routerNamespace as string);
      return;
    }
  }
};

/**
 * Initializes the global store backwards compatible with the old seamless store versions
 * @param env
 * @returns
 */
export const globalStoreInitializer = (env: Environment = 'prod') => {
  const isStoreAvailable = (): boolean => {
    return !!topMostWindow?.seamlessStore;
  };

  const store = initializeStore(env);
  if (!topMostWindow.seamlessStore) {
    topMostWindow.seamlessStore = {
      store: store,
      initializeStore: initializeStore,
      isStoreAvailable: isStoreAvailable,
      SeamlessConnection: SeamlessConnection,
      PersistentConnection: PersistentConnection,
      ConnectionExistsError: ConnectionExistsError,
      InvalidConnectionNameError: InvalidConnectionNameError,
    };
  }

  // Sealing the store so it won't be modified. EG: Vue was transforming the store into an Vue Observer -> https://git.daimler.com/dh-io-mbmxp/seamless/issues/1040
  Object.seal(topMostWindow.seamlessStore);
  Object.seal(topMostWindow.seamlessStore.store);

  return topMostWindow.seamlessStore.store;
};

/**
 * Initializes the global logger backwards compatible with the old seamless logger versions
 * @param namespace
 * @returns
 */
export const globalLoggerInitializer = (namespace?: string) => {
  const logger = initializeLogger(namespace);
  if (!topMostWindow.seamlessLogger) {
    topMostWindow.seamlessLogger = {
      seamlessLoggerInstance: new SeamlessLogger(),
      initializeLogger: initializeLogger,
    };
  }

  return logger;
};
